<template>
  <div>
    <vs-row
      class="dashboard h-full md:pt-10 md:pr-10"
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        class="w-full md:w-1/4"
      >
        <div class="sidenav w-full md:w-1/4">
          <div class="flex items-center justify-between">
            <h2>Centres</h2>
          </div>

          <div class="mt-10">
            <div
              class="my-6"
              v-if="Object.entries(this.centreData).length !== 0"
            >
              <h2 class="mb-3">{{ selectedCountry }}</h2>
              <h3 class="mb-10" style="color: #414141">{{ selectedRegion }}</h3>

              <VuePerfectScrollbar
                class="scroll-area--data-list-add-new"
                :settings="settings"
                :key="$vs.rtl"
              >
                <ul class="centre-list">
                  <li
                    class="mb-6 pb-5"
                    v-for="(option, index) in learningCentreOptions"
                    :key="index"
                  >
                    <img :src="option.logo" @click="centerDashboard(option)" />
                    <h4 class="mt-2 h5" @click="centerDashboard(option)">
                      {{ option.name }}
                    </h4>
                  </li>
                </ul>
              </VuePerfectScrollbar>
            </div>
          </div>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        class="p-10 md:p-0 flex-wrap h-full ph-map-area w-full"
      >
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="w-full" align="right">
              <vs-button
                color="primary"
                type="filled"
                @click="addNewLearningCentre"
                v-if="!director.isRegionalDirector"
                >Add Centre</vs-button
              >
            </div>
          </vs-col>
        </vs-row>

        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="lg:mr-8 mr-4">
              <div>
                <div>
                  <v-select
                    :options="countryOptions"
                    v-model="countryFilter"
                    class="mb-8 country-option"
                  >
                    <template #header>
                      <label for class="label">Country</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-if="attributes" v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <v-select
                    :options="stateOptions"
                    v-model="stateFilter"
                    class="region-option"
                  >
                    <template #header>
                      <label for class="label">Select Region</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-if="attributes" v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>

        <vs-row
          class="dashboard mt-8 h-full"
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
            <div class="mb-8 w-full h-full">
              <gmap-map
                ref="googleMap"
                :center="center"
                :zoom="zoom"
                style="width: 100%; min-height: 100vh; height: 100%"
                class="no-padding"
                :options="getOptions()"
              >
                <gmap-info-window  
                  :options="infoOptions" 
                  :position="infoWindowPos" 
                  :opened="infoWinOpen" 
                  @closeclick="infoWinOpen=false"
                >
                <div v-html="infoContent"></div>
                </gmap-info-window>
             

                <GmapCluster :zoomOnClick="true" :maxZoom="8">
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    @click="toggleInfoWindow(m,index)"
                  ></gmap-marker>
                </GmapCluster>
              </gmap-map>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import _ from "lodash";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import gmapInfoWindow from "vue2-google-maps/dist/components/infoWindow";
import { gmapApi } from "vue2-google-maps";

export default {
  data() {
    return {
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      dataTableParams: {
        country: "all",
        state: "all",
      },
      selectedCountry: "",
      selectedRegion: "",
      centreData: [],
      learningCentreOptions: [],
      countryOptions: [],
      stateOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      addNewDataSidebar: false,
      sidebarData: {},
      addNewCenter: false,
      searchResults: null,
      center: {
                lat: parseFloat(39.000117),
                lng: parseFloat(33.999989),
              },
      markers: [],
      places: [],
      currentPlace: null,
      zoom: 2,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoContent: '',
      infoOptions: {
          //optional: offset infowindow so it visually sits nicely on top of our marker
          pixelOffset: {
            width: 0,
            height: -35
          }
      }
    };
  },
  components: {
    vSelect,
    VuePerfectScrollbar,
    GmapCluster,
    gmapInfoWindow
  },
  computed: {
    google: gmapApi,

    director() {
      return this.$store.state.AppActiveUser;
    },
  },
  async mounted() {
  await this.getDirectorSpeceficCountries()
    .then(async (res) => {
      this.countryOptions = this.$store.state.director.directorCountryOptions;
      await this.getCenterList()
        .then(response => {
        this.$refs.googleMap.$mapPromise.then(async (map) => {
          try {
            var bounds = await new this.google.maps.LatLngBounds();
            // success handling code here
          
            if (this.markers === 'undefined' || this.markers.length <= 0) {
              console.log("if (this.markers === 'undefined' || this.markers.length <= 0) =---------------- ");
              this.center = {
                lat: parseFloat(39.000117),
                lng: parseFloat(33.999989),
              };
              this.zoom = 2;
            } else {
              // if (!isFinite(this.center.lat) || !isFinite(this.center.lng)) {
              //   console.warn('Center coordinates are not finite numbers, setting default values');
              //   this.center = {
              //     lat: 39.000117,
              //     lng: 33.999989,
              //   };
              //   this.zoom = 2;
              // }
              
              var self=this;
              for (var i = 0; i < this.markers.length; i++) {
                await bounds.extend(this.markers[i].position);
              }
              console.log("r", bounds);
              await map.fitBounds(bounds);
              var listener = await new this.google.maps.event.addListenerOnce(map, "idle", function() { 
                if (map.getZoom() > 12) map.setZoom(6); 
                self.google.maps.event.removeListener(listener); 
              });
            }
            
            // Check if center coordinates are finite numbers
          } catch (err) {
            console.error(err);
            console.log('1 =---------------- ')
          }
        });
      }).catch(error => {
        console.log('2 =---------------- ')

        console.error('err1>>',error)
      });
    })
    .catch((err) => {
      console.log('3 =---------------- ')

      console.error('err2>>',err)
    }); 
},

  methods: {
    ...mapActions("center", ["getAllCountries", "getCentreByRegion"]),
    ...mapActions("director", ["getDirectorSpeceficCountries"]),

    async getCenterList() {
      this.dataTableParams.country =
        this.countryFilter.value == "all" ? "" : this.countryFilter.value;
      this.dataTableParams.state =
        this.stateFilter.value == "all" ? "" : this.stateFilter.value;

      this.countryFilter.value == "all" ? (this.zoom = 2) : (this.zoom = 4);
      this.$vs.loading();
      await this.getCentreByRegion(this.dataTableParams)
        .then(async (res) => {
          this.markers = [];
          this.centreData = res.data.data;
          this.selectedCountry = this.dataTableParams.country
            ? this.dataTableParams.country
            : "All Countries";
          this.selectedRegion = this.dataTableParams.state
            ? this.dataTableParams.state
            : "All States";
          this.learningCentreOptions = this.centreData.map((item) => {
            return {
              logo: item.logo,
              name: item.name,
              id: item._id,
            };
          });
          this.searchResult = await this.centreData.map((item) => {
            return {
              lat: item.address.location.coordinates
                ? parseFloat(item.address.location.coordinates[1])
                : "",
              lng: item.address.location.coordinates
                ? parseFloat(item.address.location.coordinates[0])
                : "",
              detail: {
                id: item._id,
                logo: item.logo,
                name: item.name,
                phone: item.phoneNumber,
                email: item.email

              }
            };
          });
          this.searchResult = _.filter(this.searchResult, (item) => {
            return item.lat !== "" || item.lng !== "";
          });
          if(this.searchResult.length == 1){
            this.zoom= 4;
          }
          this.$vs.loading.close();
          await this.addMarker();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    addNewLearningCentre() {
      this.$router.push({ name: "director-learning-centre-add" });
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    refreshData() {
      this.getDirectorSpeceficCountries()
        .then((res) => {
          this.countryOptions =
            this.$store.state.director.directorCountryOptions;
          this.getCenterList();
        })
        .catch((err) => {
          // console.error(err)
        });
    },
    getOptions() {
      return {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      };
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: parseFloat( this.currentPlace.geometry.location.lat() ),
          lng: parseFloat( this.currentPlace.geometry.location.lng() ),
        };
        this.markers.push({ position: marker});
        this.places.push(this.currentPlace);
        this.center = marker;
        // this.center = marker.getPosition();
        this.currentPlace = null;
      }
      if (this.searchResult[0]) {
        this.center = {
          lat: parseFloat( this.searchResult[0].lat ),
          lng: parseFloat( this.searchResult[0].lng ),
        };
        for (let i = 0; i < this.searchResult.length; i++) {
          const marker = {
            lat: parseFloat( this.searchResult[i].lat ),
            lng: parseFloat( this.searchResult[i].lng ),
          };
          this.markers.push({ position: marker, detail: this.searchResult[i].detail });
          this.places.push(this.searchResult[i]);
        }
        this.searchResult = null;        
      }

    },
    geoLocate: function () {
      // this.center = {
      //   // lat: parseFloat(-33.8548157),
      //   // lng: parseFloat(151.2164539),
      // };
    },

    centerDashboard(data) {
      this.$router.push({
        name: "director-center-dashboard",
        params: { centerId: data.id },
        query: { title: data.name },
      });
    },

    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      // this.infoOptions.content = marker.infoText;
      this.infoContent = this.getInfoWindowContent( marker.detail);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    },
    getInfoWindowContent: function (marker) {
      let option = {
        id: marker.id,
        name: marker.name
      };
        return (`<div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <img src="${marker.logo}" alt="${marker.name}" width="200px"  />
              </figure>
            </div>
            <div class="card-content">
              
              <div class="content">
                <h3 class="title is-4"  >${marker.name}</h3>
                <b>Phone:</b> ${marker.phone}
                <br>
                <b>Email:</b> ${marker.email}
                <br>
              </div>
            </div>
          </div>`);
    },
    

  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.stateFilter = { label: "All", value: "all" };
    },
    stateFilter(obj) {
      this.getCenterList();
    },
  },
  async created() {
  },
};
</script>
<style lang="scss" scoped>
.sidenav {
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 10;
  background-color: white;
  overflow: visible;
  padding-top: 40px;
  padding-bottom: 40px;
  @media (min-width: 768px) {
    padding-top: 130px;
    height: 100%;
    width: 370px;
    position: fixed;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 25px;
  }

  .select-wrapper {
    max-width: 218px;
  }
}

.input-wrap {
  padding: 0 30px;
  @media (max-width: 576px) {
    padding: 0;
  }
}

.scroll-area--data-list-add-new {
  height: calc(100vh - 483px) !important;
}

.centre-list {
  li {
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;

    img {
      width: 100%;
      max-width: 257px;
      height: auto;
      object-fit: cover;
      border: 1px solid #979797;
    }
  }
}

#mapId {
  height: 180px;
}

.vs-con-input-label.is-label-placeholder {
  margin-top: 30px !important;
}

.input-wrap {
  padding: 0 30px;
  @media (max-width: 576px) {
    padding: 0;
  }
}

.ph-map-area {
  @media (min-width: 768px) {
    padding-left: calc(25% + 50px) !important;
  }
}
</style>
